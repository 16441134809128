<template>
  <article class="interviewCard">
    <div class="interviewCard__wrapper">
      <div class="interviewCard__header">
        <figure>
          <img :src="talentProfilePic" :alt="interviewCard.talent.email" @error="imageLoadOnError" />

          <div v-if="interviewCard.talent?.country?.code" class="">
            <country-flag :countryCode="interviewCard.talent.country.code" class="country" />
          </div>
        </figure>
        <div class="interviewCard__headings">
          <h3>
            {{ interviewCard.talent?.full_name }}
          </h3>
          <div class="interviewCard__role">
            <span class="tw-text[#3d3d3d]">
              {{ interviewCard.talent.role?.name }}
            </span>
            <span class="tw-text-2xl tw-px-2">•</span>
            <template v-if="Number(interviewCard.talent.experience_years) > 0 && interviewCard.talent.experience_years != null">
              <span>
                {{ interviewCard.talent.experience_years }} {{ interviewCard.talent.experience_years == 1 ? $t('nomination.yearOfExperience') : $t('nomination.yearsExperience') }}
              </span>
            </template>
            <span v-else-if="Number(interviewCard.talent.experience_years) == 0 && interviewCard.talent.experience_years != null">
              {{ $t('matchedTalents.fresh') }}
            </span>
          </div>
          
          <div class="interviewCard__details">
            <img src="@/assets/images/ic_clock.svg" alt="CLock" />
            <h4>
              <span>{{ $t('interviewsList.createdAt') }}:</span>
              <span>{{ meetingTime(interviewCard.created_at) }}</span>
            </h4>
          </div>
          <div class="interviewCard__details">
            <img src="@/assets/images/ic_clock.svg" alt="CLock" />
            <h4>
              <span>{{ $t('interviewsList.meetingTime') }}:</span>
              <span class="interviewCard__ltr tw-me-1" v-if="interviewCard.meeting_start_time" >{{ meetingTime(interviewCard.meeting_start_time) }}</span>
              <span class="interviewCard__ltr" v-else>{{ $t('interviewsList.loading') }}</span>
            </h4>
          </div>
          <div v-if="interviewCard.created_by && user.id !== interviewCard.created_by.id" class="interviewCard__details">
            <img src="@/assets/images/user-pen.svg" alt="CLock" />
            <h4>
              <span>{{ $t('interviewsList.createdBy') }}:</span>
              <span class="tw-me-1" v-if="interviewCard.created_by.first_name" >
                {{ interviewCard.created_by.first_name }} {{ interviewCard.created_by.last_name }}
              </span>
            </h4>
          </div>
        </div>
        <div class="interviewCard__meetingCreated interviewCard__ltr">
          <span v-if="interviewCard.meeting_start_time">
            {{ whenMeetingBooked(interviewCard.meeting_start_time) }}
          </span>
          <span v-else>
            {{ $t('interviewsList.loading') }}
          </span>
        </div>
      </div>
      <div class="interviewCard__actions">
        <div class="interviewCard__copy">
          <span>
            {{ $t('interviewsList.interviewLink') }}:
          </span>
          <div>
            <span dir="ltr" v-if=" interviewCard.meeting_link">
              {{ interviewCard.meeting_link | truncate}}
            </span>
            <span v-else>
              {{ $t('interviewsList.loading') }}
            </span>
            <button @click="copyInterviewLink">
              <img src="@/assets/images/copy.svg" alt="Copy url">
            </button>
          </div>
        </div>
        <a :href="interviewCard.talent.sharable_link" target="_blank">
          <span>
              {{ $t('interviewsList.viewProfile') }}
          </span>
          <img src="@/assets/images/ic_arrow-dropdown_1.svg" alt="Arrow" />
        </a>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import toast from "@/services/toast.js";
import CountryFlag from "@/components/shared/countryFalg/countryFalg.vue";

export default {
  name: "TalentRenewalCard",
  components: {
    CountryFlag,
  },
  props: {
    interviewCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
    };
  },
  filters: {
    truncate(value) {
      return value.length > 35 ? value.substring(0, 35) + "..." : value;
    },
  },
  methods: {
    meetingTime(time) {
      if (this.$i18n.locale == "en") {
        moment.locale("en")
        return `${moment(new Date(time)).format("DD/MM/YYYY")} at ${moment(new Date(time)).format(" h:mm A")} `
      } else {
        moment.locale("ar")
        return `${moment(new Date(time)).format("YYYY/MM/DD")} فى ${moment(new Date(time)).format(" h:mm A")} `
      }
    },
    whenMeetingBooked(time) {
      this. $i18n.locale == "en" ? moment.locale("en") : moment.locale("ar");
      return moment(new Date(time)).fromNow();
    },
    copyInterviewLink() {
      navigator.clipboard.writeText(this.interviewCard.meeting_link);
      toast.success(this.$t("interviewsList.copied"));
    },
    imageLoadOnError(e) {
      e.target.src =  'https://squadio-fe-stage.ibtik.com/img/talent-avatar.227b992c.png'
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    talentProfilePic() {
      if (this.interviewCard.talent.image && this.interviewCard.talent.image)
        return `${this.interviewCard.talent.image}`;
      else return require("@/assets/images/talent-avatar.png");
    },
  },
};
</script>

<style lang="scss">
.interviewCard {
  display: flex;
  align-items: center;
  width: 100%;

  &__wrapper {
    width: 100%;
    border: 1px solid #eeeeee;
    padding: 26px 16px;
    border-radius: 20px;
    box-shadow: 9px 7px 45px 0 rgba(66, 46, 135, 0.09);
    margin: 20px 0;

    @media (min-width: 768px) {
      padding: 32px 26px;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    figure {
      position: relative;
    }

    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 50px;
        height: 50px;
      }
    }

    .country {
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: 0;
      background-color: #ccc;
     
      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 50%;
        
        @media (max-width: 768px) {
            width: 20px;
            height: 20px;
        }
      }
      
      @media (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__mainInfo {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;

    figure {
      position: relative;
    }
  }

  &__headings {
    margin-inline-end: auto;
    h3 {
      display: flex;
      font-size: 18px;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &__meetingCreated {
    background-color: #fafafa;
    border: 1px solid #f1f1f1;
    border-radius: 20px;
    padding: 7px 12px;
    white-space: nowrap;

    span {
      font-size: 12px;
      color: #9f9d9d;
      padding: 8px 16px;
    }

    @media (max-width: 768px) {
      align-self: flex-end;

      span.tw-px-2 {
        display: none;
      }
    }
  }

  &__role {
    margin-top: 8px;
    display: flex;
    align-items: center;
    

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;

      span.tw-px-2 {
        display: none;
      }
    }

    span {
      color: #422e87;
    }
    span:first-child {
      color: #3d3d3d;
      font-weight: 600;
      font-size: 14px;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    span:last-child {
      color: #9f9d9d;
      margin-top: 2px;
      font-size: 14px;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__ltr {
    direction: ltr;
  }

  &__details {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    margin-top: 6px;

    h4 {
      width: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        span:last-child {
          font-size: 10px;
        }
      }
    }

    span:first-child {
      color: #494d55;
      padding-inline-end: 8px;
    }

    span:last-child {
      color: #3e3e3e;;
    }

    img {
      width: 15px;
      height: 15px;
      
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      border: 1px solid #f1f1f1;
      background-color: #fff;
      padding: 7px 20px;
      border-radius: 30px;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }

      span {
        white-space: nowrap;
      }
    }

    img {
      width: 8px;
      height: 13px;
    }
  }

  &__copy {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #fafafa;
    border: 1px solid #f1f1f1;
    padding: 7px 20px;
    border-radius: 27px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }  

    span {
      font-size: 14px;
      color: #3d3d3d;
      white-space: nowrap;
    }

    & > span {
        font-size: 14px;
        color: #422e87;
        font-weight: 600;
    }

    div {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
    }

    button {
      width: 18px;
      height: 20px;
      background-color: transparent;
      outline: none;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.country-flag {
  position: absolute;
  bottom: -5px;
  right: -5px;
  .country-flag-wrapper {
    width: 32px;
    height: 32px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

html[dir="rtl"] {
  .interviewCard {
    &__contractDateArrow {
      transform: rotate(180deg);
    }

    &__actions {
        a img {
            transform: rotate(180deg);
        }
    }
  }
}
</style>
